$white: #FFFFFF;
$grey: #9D9E9E;
$greytransparent: #9D9E9E44;
$lightgrey: #F4F4F4;
$lightgreytransparent: #F4F4F444;
$black: #000000;
$orange: #EF7F1A;
$yellow: #FFD500;
$red: #EF1A1A;

$border-radius: 6px;
$icon-size: 24px;
