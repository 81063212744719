@import "styles/_variables.scss";
//@import "styles/_fonts.scss";

html, body, #root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  background-color: $lightgrey;

  &__private {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1 1;
    position: relative;

    &__menu {
      width: 240px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      background-color: $white;
      border-right: solid 1px $grey;

      @media (max-width: 767px) {
        display: none;

        &.active {
          display: block;
          position: absolute;
          top:0;
          left: 0;
          width: 100vw;
        }
      }

      &__logo {
        width: inherit;
        border-bottom: solid $grey 1px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
          width: 142px;
          height: 38px;
          margin-left: 20px;
        }
      }

      &__list {
        width: inherit;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        align-items: flex-start;
        justify-content: flex-start;

        &__main {
          width: inherit;
          padding-top: 5px;
          padding-bottom: 5px;
          border-bottom: solid 1px $grey;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &__exit {
          width: inherit;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          flex: 1 1;
        }

        &__settings {
          width: inherit;
          padding-top: 5px;
          border-top: solid 1px $grey;
          padding-bottom: 30px;
        }

        &__item, &__item:visited, &__item:active, &__item:hover {
          width: inherit;
          height: 54px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;
          padding: 0 20px;
          cursor: pointer;
          color: black;
          text-decoration: none;

          span {
            font-size: 14px;
          }

          svg {
            width: $icon-size;
            height: $icon-size;
            fill: $black;
          }

          &.active {
            border-left: solid $black 6px;
            padding-left: 14px;

            svg {
              fill: $orange;
            }
          }
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1 1;
      width: calc(100vw - 240px);
      height: 100vh;

      @media (max-width: 767px) {
        width: 100vw;
      }

      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: $white;
        height: 60px;
        width: inherit;
        border-bottom: solid $grey 1px;

        .row {
          align-items: center;
          flex-wrap: nowrap;

          .mobile {
            margin-left: 20px;
          }
        }

        &__breadcrumbs {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-left: 20px;

          @media (max-width: 767px) {
            padding-left: 10px;
          }

          div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 3px;
            margin-bottom: 7px;

            @media (max-width: 767px) {
              display: none;
            }

            a, a:hover, a:active, a:visited {
              color: $grey;
              font-size: 13px;
              cursor: pointer;
              text-decoration: none;
            }
          }

          h1 {
            font-size: 17px;
            font-weight: 500;
            margin: 0;

            @media (max-width: 767px) {
              font-size: 13px;
            }
          }
        }

        > div:last-child {
          height: inherit;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          border-left: solid $grey 1px;
          padding: 0 20px 0 20px;
          gap: 10px;

          a, a:hover, a:visited, a:active {
            color: $black;
            cursor: pointer;
            text-decoration: none;
          }

          svg {
            width: $icon-size;
            height: $icon-size;
            fill: $black;
          }
        }
      }

      &__page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 1;
        width: calc(100vw - 240px);
        height: calc(100vh - 60px);

        @media (max-width: 767px) {
          width: 100vw;
        }
      }
    }

    &__page {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1 1;
      width: inherit;

      &__content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1 1;
        gap: 18px;
        padding: 20px;
        flex-wrap: wrap;
        width: calc(100vw - 280px);
        overflow: auto;
        max-height: calc(100vh - 160px);

        @media (max-width: 767px) {
          width: calc(100vw - 20px);
          padding: 10px;
          max-height: calc(100vh - 150px);
        }

        &__half {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 18px;
          flex: 1 1;

          &.noGap {
            gap: 0;
          }
        }

        &__block {
          padding: 18px;
          background-color: $white;
          border-radius: $border-radius;
          display: inline-block;
          width: 100%;
          box-sizing: border-box;

          &__title {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
          }

          &__subtext {
            font-size: 12px;
            color: $grey;
          }

          &__between {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          &__right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
          }

          h4 {
            font-size: 14px;
            color: $black;
            font-weight: 500;
            margin: 0;
            padding-bottom: 18px;

            span {
              font-weight: 200;
              margin-left: 20px;
            }
          }

          svg {
            width: $icon-size;
            height: $icon-size;
            fill: $white;
            stroke: $white;
          }
        }

        &__table {
          display: table;
          width: 100%;
          background-color: $white;
          border-radius: $border-radius;
          border-collapse: collapse;
          table-layout: fixed;

          &__row {
            display: table-row;
            width: 100%;
            clear: both;
            padding: 10px 20px;
            color: $black;
            font-size: 14px;
            cursor: pointer;

            @media (max-width: 767px) {
              display: flex;
              flex-direction: column;
              border-bottom: solid 1px $lightgrey;
              width: calc(100vw - 50px);
            }

            &.header {
              font-weight: 600;
              border-bottom: 1px $black solid;

              @media (max-width: 767px) {
                display: none;
              }
            }

            &:not(.header):hover {
              background-color: $lightgrey;
            }

            &__cell {
              float: left;
              display: table-cell;
              padding: 10px 20px;
              font-weight: 300;

              @media (max-width: 767px) {
                display: block;
                width: calc(100vw - 80px) !important;
                padding: 5px 10px;
              }

              &.per10 {
                width: calc(10% - 40px);
              }

              &.per20 {
                width: calc(20% - 40px);
              }

              &.per30 {
                width: calc(30% - 40px);
              }

              &.bold {
                font-weight: 600;
              }
            }
          }

          &__bordered {
            border-radius: 0;
            outline: solid 2px $greytransparent;

            width: 100%;
            background-color: $white;
            border-collapse: collapse;
            table-layout: fixed;
            display: block;
            overflow: scroll;

            > div {
              table-layout: fixed;
              display: table;
              min-width: 100%;
              border-collapse: collapse;
              height:100%;
            }

            &__splitter {
              display: table-row;
              width: 100%;
              padding: 0;
              clear: both;
              height: 12px;
              background-color: $greytransparent;

              &__cell {
                float: left;
                display: table-cell;
                padding: 0;
                font-weight: 300;
                //background-color: $greytransparent;
                height: 100%;
                width: 100%;
              }
            }

            &__row {
              display: flex;
              flex: 1 1;
              flex-direction: row;
              width: auto;
              clear: both;
              color: $black;
              outline: 1px solid $greytransparent;
              font-size: 14px;
              height: auto;

              &:last-child {
                > div {
                  border-bottom: none !important;
                }
              }

              &__cell {
                display: flex;
                flex-direction: column;
                padding: 5px 10px;
                font-weight: 300;
                font-size: 12px;

                @media (min-width: 768px) {
                  outline: 1px solid $greytransparent;
                }

                > div {
                  height: 100%;
                }

                > span {
                  white-space: wrap;
                }

                &:last-child {
                  border-right: 0;
                }

                &.per10 {
                  width: calc(10% - 20px);

                  @media (max-width: 767px) {
                    width: calc(25% - 20px);
                  }
                }

                &.per20 {
                  width: calc(20% - 20px);

                  @media (max-width: 767px) {
                    width: calc(35% - 20px);
                  }
                }

                &.per30 {
                  width: calc(30% - 20px);

                  @media (max-width: 767px) {
                    width: calc(40% - 20px);
                  }
                }

                &.per40 {
                  width: calc(40% - 20px);

                  @media (max-width: 767px) {
                    width: calc(65% - 20px);
                  }
                }

                &.per50 {
                  width: calc(50% - 20px);
                }

                &.bold {
                  font-weight: 600;
                }
              }
            }
          }
        }

        &__search {
          margin-bottom: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          flex-grow: 1;

          > input, > select {
            display: flex;
            flex: 1;
          }
        }
      }

      &__footer {
        width: inherit;
        height: 66px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        border-top: solid $grey 1px;

        &.between {
          justify-content: space-between;
        }

        > :first-child {
          margin-left: 20px;
        }

        > :last-child {
          margin-right: 20px;
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &__listitem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 450px;
        border: solid $grey 1px;
        border-radius: 5px;
        padding: 12px;
        margin-bottom: 7px;
        background-color: $white;
        font-size: 14px;

        @media (max-width: 767px) {
          width: calc(100vw - 50px);
        }

        .fw-700 {
          font-weight: 700;
          margin-right: 10px;
        }
      }

      .row {
        justify-content: space-between;

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          select, input, .items {
            width: 207px;

            @media (max-width: 767px) {
              width: calc(100vw - 50px);
            }
          }
        }
      }

      select, input, textarea, .items {
        margin-bottom: 20px;
        width: 450px;

        @media (max-width: 767px) {
          width: calc(100vw - 50px);
        }
      }
    }
  }

  .page {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    &.center {
      align-items: center;
      justify-content: center;
    }

    .public {
      &__logo {
        width: 294px;
        height: 140px;
        margin-bottom: 42px;
      }

      &__form {
        width: 342px;
        background-color: $white;
        padding: 30px;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 17px;
          font-weight: 400;
          text-align: center;
          margin-bottom: 24px;
        }

        select, input, .items {
          width: 317px;

          margin-bottom: 10px;
        }
      }
    }
  }
}

.column {
  display: flex;
  flex-direction: column !important;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  &.a-center {
    align-items: baseline;
    justify-content: center;
  }
}

.flex-1 {
  display: flex;
  flex: 1;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.ui {
  &__image {
    width: 100%;
    max-width: 200px;
    max-height: 200px;

    > img {
      width: 100%;
      max-width: 200px;
      max-height: 200px;
      object-fit: contain;
      border-radius: $border-radius;
      cursor: pointer;
    }

    > div {
      display: none;

      &.active {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background-color: $lightgreytransparent;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;

        > div {
          width: 80vw;
          height: 80vh;
          background-color: $white;
          border-radius: $border-radius;
          border: solid 1px $grey;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          > div {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: flex-end;
            justify-content: flex-end;

            > * {
              margin: 10px;
            }
          }

          > img {
            width: 90%;
            max-height: 90%;
            object-fit: contain;
            border-radius: $border-radius;
            align-self: center;
          }
        }
      }
    }
  }

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $lightgreytransparent;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__content {
      width: 354px;
      border-radius: $border-radius;
      border: 1px solid $grey;
      background-color: $white;
      padding: 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__control {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        width: 100%;
      }

      svg {
        width: 64px;
        height: 64px;
      }

      p {
        font-size: 14px;
        font-weight: 300;
        color: $black;
        text-align: center;
      }
    }

    &__list {
      width: 330px;
      margin-bottom: 10px;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;

      &__item {
        padding: 12px;
        width: 100%;
        border-bottom: solid $lightgrey 1px;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        &:hover {
          background-color: $lightgrey;
          cursor: pointer;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  &__button {
    border-radius: $border-radius;
    cursor: pointer;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.black {
      color: $white;
      background-color: $black;

      svg {
        fill: $white;
      }
    }

    &.white {
      color: $black;
      background-color: $white;

      svg {
        fill: $black;
      }
    }

    &.grey {
      color: $white;
      background-color: $grey;

      svg {
        fill: $white;
      }
    }

    &.red {
      color: $white;
      background-color: $red;

      svg {
        fill: $white;
      }
    }

    &.transparent {
      color: $black;
      background-color: #ffffff00;

      svg {
        fill: $black;
      }
    }

    &.s {
      padding: 9px 12px;
      font-size: 14px;

      svg {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }

    &.l {
      padding: 12px;
      font-size: 14px;

      svg {
        width: $icon-size;
        height: $icon-size;
        margin-right: 5px;
      }
    }

    &.xl {
      padding: 16px 18px;
      font-size: 17px;

      svg {
        width: $icon-size;
        height: $icon-size;
        margin-right: 5px;
      }
    }
  }

  &__input {
    border-radius: $border-radius;
    background-color: $white;
    color: $black;
    font-size: 14px;
    border: 1px solid $grey;
    z-index: 2;

    &:focus {
      outline: none;
      border: 1px solid $black;
    }

    &::placeholder {
      color: $grey;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: $grey;
    }

    &:disabled {
      position: relative;
      background: url(./asset/image/input_lock.png) no-repeat $icon-size $icon-size;
      background-position: right 7px center;

      &.xl {
        padding: 18px 30px 18px 12px;
      }

      &.l {
        padding: 12px 30px 12px 12px;
      }
    }

    &.xl {
      padding: 18px 12px;
    }

    &.l {
      padding: 12px;
    }

    &.white {
      background-color: $white;
    }

    &.grey {
      background-color: $lightgrey;
    }

    &.items {
      margin-top: -35px;
      margin-bottom: 20px;
      z-index: 1;
      overflow: auto;
      max-height: 125px;
      position: relative;

      > div {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        padding: 10px;
        gap: 5px;
        height: 30px;
        width: 95%;
        border-bottom: solid 1px $lightgrey;
        cursor: pointer;


        &:hover{
          background-color: $lightgrey;
        }

        svg {
          fill: $grey;
        }
      }
    }
  }

  &__checkbox {
    border-radius: 2px;
    background-color: $white;
    color: $black;
    font-size: 14px;
    border: 1px solid $grey;

    &.checked {
      background-color: $black;

      svg {
        fill: $white;
      }
    }

    &.disabled {
      background-color: $lightgrey;
    }

    &.xl {
      width: $icon-size;
      height: $icon-size;

      svg {
        width: $icon-size;
        height: $icon-size;
      }
    }

    &.l {
      width: 18px;
      height: 18px;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    // for switch
    &__switch {
      border: 1px solid $grey;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      > div {
        background-color: $lightgrey;
      }

      &.checked {
        background-color: $black;
        justify-content: flex-end;

        > div {
          background-color: $white;
        }
      }

      &.disabled {
        background-color: $lightgrey;
        justify-content: space-between;

        > div {
          background-color: $grey;
        }

        > svg {
          fill: $grey;
        }

        &.checked {
          background-color: $grey;

          > div {
            background-color: $lightgrey;
          }

          > svg {
            fill: $lightgrey;
          }
        }
      }

      &.xl {
        width: 48px;
        border-radius: 12px;
        padding: 3px;

        > div {
          width: 18px;
          height: 18px;
          border-radius: 9px;
        }

        svg {
          width: 18px;
          height: 18px;
        }
      }

      &.l {
        width: 33px;
        border-radius: 9px;
        padding: 3px;

        > div {
          width: 12px;
          height: 12px;
          border-radius: 9px;
        }

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  &__pagination {
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: solid 1px $lightgrey;
    margin-bottom: 10px;

    > div {
      width: 42px;
      height: 42px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      color: $black;
      background-color: $white;
      cursor: pointer;

      &:hover {
        background-color: $lightgrey;
      }

      &.dots {
        cursor: initial;

        &:hover {
          background-color: $white;
        }
      }

      &.active {
        color: $white;
        background-color: $black;
      }
    }
  }

  &__spinner {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    &__text {
      font-size: 14px;
      color: $black;
    }

    &__icon {
      transform: rotateZ(45deg);
      perspective: 1000px;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      color: $yellow;

      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        transform: rotateX(70deg);
        animation: 1s spin linear infinite;
      }

      &:after {
        color: $orange;
        transform: rotateY(70deg);
        animation-delay: .4s;
      }
    }

    @keyframes rotate {
      0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
      }
    }

    @keyframes rotateccw {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(-360deg);
      }
    }

    @keyframes spin {
      0%,
      100% {
        box-shadow: .2em 0px 0 0px currentcolor;
      }
      12% {
        box-shadow: .2em .2em 0 0 currentcolor;
      }
      25% {
        box-shadow: 0 .2em 0 0px currentcolor;
      }
      37% {
        box-shadow: -.2em .2em 0 0 currentcolor;
      }
      50% {
        box-shadow: -.2em 0 0 0 currentcolor;
      }
      62% {
        box-shadow: -.2em -.2em 0 0 currentcolor;
      }
      75% {
        box-shadow: 0px -.2em 0 0 currentcolor;
      }
      87% {
        box-shadow: .2em -.2em 0 0 currentcolor;
      }
    }

  }

  &__label {
    font-size: 14px;
    color: $grey;
    text-align: left;
    align-self: flex-start;
    margin-bottom: 10px;

    &.red {
      color: $red;
      text-align: center;
      align-self: center;
    }
  }
}

.withIcon {

  div {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;

    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      margin-right: 15px;
    }
  }
}

.iconImage {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-left: 15px;
}

.roundCount {
  background-color: #000000;
  color: #F4F4F4;
  font-size: 14px;
  height: 19px;
  min-width: 19px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}
.roundCountTop {
  background-color: $orange;
  color: #F4F4F4;
  font-size: 10px;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -5px;
}

.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}

.w-120px {
  width: 120px !important;
}

.w-328px {
  width: 328px !important;
}

.mb-10px {
  margin-bottom: 10px;
}

hr {
  border-color: rgba(0, 0, 0, .1)
}

.pointer {
  cursor: pointer;
}

.littleText {
  font-size: 12px;
  line-height: 13px;
  font-weight: 100;
}

.userList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  &__item {
    font-size: 14px;
    line-height: 14px;
    padding: 4px 11px;
    background-color: $grey;
    color: black;
    border-radius: 11px;
  }
}

.tableList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__item {
    width: 100%;
    padding: 5px 0;
    border-bottom: solid 1px $grey;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    &:last-child {
      border-bottom: none;
    }
  }
}

.img200px {
  width: 200px;
  max-height: 200px;
  object-fit: contain;
}

.notificationList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  gap: 10px;

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
    border: solid 1px $grey;
    border-left: solid 3px $white;
    border-radius: $border-radius;
    background-color: $white;
    width: 100%;

    .grey {
      color: $grey;
    }
    .bold {
      font-weight: 700;
    }

    div {
      margin: 10px;
    }

    &.active {
      border-left: solid 8px $orange;
    }
  }
}

.mobile {
  display: none;

  @media (max-width: 767px) {
    display: initial;
  }
}
.desktop {
  //display: initial;

  @media (max-width: 767px) {
    display: none;
  }
}

.rotate90 {
  transform: rotate(90deg);
}
.rotate270 {
  transform: rotate(-90deg);
}

.d-none {
  display: none;
}

.red {
  color: $red;
}

.gap-20px {
  gap: 20px;
}
